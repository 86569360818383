import React from 'react'

const About = () => {
  return (
    <div className='h-full inline-block w-full relative'>
        <h1 className='text-center text-2xl my-2 h-auto fadeIn'>Damien Ajoku</h1>
        <div className='h-5/6 w-full block lg:grid grid-cols-2'>
            <img className='whitebox bg-gray-200 artistPic fadeIn m-auto' src='images/artistPic.png' alt='' ></img>
            <p className='text-md fadeIn mx-5 mt-3 lg:text-lg lg:w-5/6 lg:m-auto '>
                <span className=''><b>Hi</b>, I’m Toronto based illustrator and digital artist, <span className='text-red-500'>Damien Ajoku. </span></span><br/><br/>
                <span className='fadeIn'>
                    I have a passion for <span className='text-red-500'>design and illustration</span> and the countless ways to visually tell a story. I am a proficient 2D artist capable of adapting to and working in a wide range of styles.<br/><br/>

                    After receiving a certificate from <span className='text-red-500'>Wexford Collegiate School of the Arts</span> in <span className='text-red-500'>Visual and Media Arts</span>, I began my post-secondary education in <span className='text-red-500'>Art Fundamentals</span> until eventually getting a diploma in <span className='text-red-500'>Illustration</span> from <span className='text-red-500'>Seneca College</span>.<br/><br/>

                    Since graduating, I’ve continued to push my boundaries and improve through <span className='text-red-500'>self-study</span> and <span className='text-red-500'>digital art commissions</span>, as well as keeping myself up to date on industry practices and technologies.<br/><br/>

                    The <span className='text-red-500'>video games</span>, <span className='text-red-500'>cartoons</span>, <span className='text-red-500'>anime</span>, <span className='text-red-500'>comics</span>, and <span className='text-red-500'>manga</span> I enjoyed throughout my childhood have had a major artistic influence on me, and because of them I am dedicated to creating new worlds, telling stories, and sharing the same sense of wonder and amazement that has inspired all my life.<br/>
                </span>
            </p>
            </div>

    </div>
  )
}

export default About
