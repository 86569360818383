import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const DropDown = (props) => {
  const [dropDownVisisble, setDropDown] = useState(false)

  return (
    <div>
        <p className='relative hover:text-red-500 m-auto cursor-pointer z-1 ml-3 text-xl' onClick={() => { setDropDown(!dropDownVisisble) }}>Galleries</p>
        {dropDownVisisble
          ? <div className='absolute z-50 bg-black bg-opacity-80 mt-2 pb-2 dropDown overflow-y-scroll'>
                {props.sections && props.sections.map((section, i) => {
                  return <p key={i}><NavLink to={ '/gallery/' + section.slug.current } className='hover:text-red-500 ml-3 block' activeClassName="text-red-600" onClick={() => { setDropDown(!dropDownVisisble) }}> {section.title}</NavLink></p>
                  // return <NavLink key={i} to={ '/gallery/' + section.slug.current } className='hover:text-red-500 ml-3 block' activeClassName="text-red-600" onClick={() => { setDropDown(!dropDownVisisble) }}>{section.title}</NavLink>
                })}
            </div>
          : null }
    </div>
  )
}

export default DropDown
