import { HashRouter, Route, Switch } from 'react-router-dom'
import './style.css'
import 'react-image-gallery/styles/css/image-gallery.css'

import NavBar from './components/navbar'
import Gallery from './components/gallery'
import HomePage from './components/homepage'
import About from './components/about'
import Footer from './components/footer'
import SinglePost from './components/singlepost'

import React, { useState, useEffect } from 'react'
import sanityClient from './client.js'
import Commissions from './components/commission'

function App () {
  const sections = useState(null)
  const images = useState([])

  useEffect(() => {
    sanityClient.fetch(`*[_type == "sections" && isactive == true ] | order(order asc) {
      title,
      slug,
      tags,
      thumbnail{
        asset->{
          url
        }
      },
      customSection,
      posts[]->{
        title,
        tags,
        slug,
        description,
        thumbnail{
            asset->{
                _id,
                url
            },
            alt
        },
        imagesGallery[]{
          desc,
          asset->{
            url
          }
        } 
      }
    }`).then((data) => {
      data.forEach(item => {
        for (let i = 0; item.tags && i < item.tags.length; i++) {
          item.tags[i] = item.tags[i].trim().toLowerCase()
        }
      })
      sections[1](data)
    }).catch(console.error)
    sanityClient
      .fetch(`*[_type == "post"] | order(_createdAt desc) {
          title,
          tags,
          slug,
          description,
          thumbnail{
              asset->{
                  _id,
                  url
              },
              alt
          },
          imagesGallery[]{
            desc,
            asset->{
              url
            }
          } 
  }`).then((data) => {
        data.forEach(item => {
          for (let i = 0; item.tags && i < item.tags.length; i++) {
            item.tags[i] = item.tags[i].trim().toLowerCase()
          }
        })
        images[1](data)
      }).catch(console.error)
    // eslint-disable-next-line
  } ,[])

  return (
    <HashRouter>
      <NavBar sections={sections[0]}></NavBar>
      <div className='mainFont text-white bg-black mainHeight mx-8 md:mx-16 lg:mx-16 xl:mx-16 overflow-hidden overflow-y-auto' id='gallery'>
        <Switch>
          {/* <Route component={ HomePage } path='/' exact/> */}
          <Route render={props => (<HomePage {...props} sections={sections[0]}/>)} path='/' exact/>
          <Route render={props => (<Gallery {...props} imgs={images[0]}/>)} path='/gallery/all' exact />
          <Route render={props => (<SinglePost {...props} imgs={images[0]}/>)} path='/post/:slug' />
          <Route render={props => (<Gallery {...props} sections={sections[0]} imgs={images[0]}/>)} path='/gallery/:slug'/>
          <Route component={ Commissions } path ='/commissions' exact />
          <Route component={ About } path ='/about' exact />
        </Switch>
      </div>
      <Footer></Footer>
    </HashRouter>
  )
}

export default App
