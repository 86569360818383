import React, { useEffect, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'

const FullView = (props) => {
  const divFocus = useRef()
  useEffect(() => {
    if (divFocus.current) divFocus.current.focus()
  }, [])

  const handlers = useSwipeable({
    onSwipedLeft: () => props.setFullImg(-1),
    onSwipedRight: () => props.setFullImg(1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
        <div className={'w-full h-full fullImg select-none ' + props.className} ref={divFocus} autoFocus onKeyDown={(e) => {
          e.preventDefault()
          switch (e.code) {
            // arrow left
            case ('ArrowLeft'):
              props.setFullImg(-1)
              break
              // arrow right
            case ('ArrowRight'):
              props.setFullImg(1)
              break
              // Escape key
            case ('Escape'):
              props.setFullImg(0)
              break
            default:
          }
        }}
        onClick={(e) => {
          e.stopPropagation()
          props.setFullImg(0)
        }}
        tabIndex="-1">

            {/* Image */}
            <a {...handlers} href= {props.imgUrl + '?fm=webp'}  target='_blank' rel='noopener noreferrer'> <img src={props.imgUrl + '?max-h=1080fm=webp'} alt="" className="object-scale-down w-11/12 absolute max-h-full m-auto inset-0" onClick={(e) => e.stopPropagation()}/>
            </a>

            {/* X button (top right) */}
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute top-0 right-0 mt-6 mr-5 cursor-pointer" viewBox="0 0 24 24" stroke="white" onClick={ () => {
              props.setFullImg(0)
            }}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            { props.numImgs > 1
              ? <div>
                    {/* Right Arrow */}
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute top-1/2 right-0 cursor-pointer" viewBox="0 0 24 24" stroke="white" onClick={
                        (e) => {
                          e.stopPropagation()
                          props.setFullImg(1)
                        }
                    }>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>

                    {/* Left Arrow */}
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute top-1/2 cursor-pointer" viewBox="0 0 24 24" stroke="white" onClick={
                        (e) => {
                          e.stopPropagation()
                          props.setFullImg(-1)
                        }
                    }>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </div>
              : null
            }

        </div>
  )
}

export default FullView
