import React from 'react'
import { useParams } from 'react-router-dom'
import PostGalleryIcon from './postgalleryicon.js'

const Gallery = (props) => {
  const images = props.imgs
  let currGallery; let currGalleryImages = null

  const { slug } = useParams()

  function getCurrentGallery (item) {
    return item.slug.current === slug
  }

  function filterImages (item) {
    // Check if the tags exist. If they do, make sure that every tag in the gallery exists in the image
    return item.tags ? currGallery[0].tags.every(r => item.tags.includes(r.trim())) : false
  }

  if (props.sections && slug !== 'all') {
    currGallery = props.sections.filter(getCurrentGallery)
    currGalleryImages = null
    if (currGallery.length !== 0 && currGallery[0].customSection) {
      currGalleryImages = currGallery[0].posts
    } else if (images && currGallery.length !== 0 && currGallery[0].tags === null) {
      currGalleryImages = images
    } else if (images && currGallery.length !== 0) {
      currGalleryImages = images.filter(filterImages)
    } else {
      return (<p className='text-white'>Gallery does not exist</p>)
    }
  } else {
    currGalleryImages = images
  }

  return (
    <section className='grid gap-1 grid-cols-2 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3' key={slug}>
        {currGalleryImages && currGalleryImages.map((image, i) => {
          return <PostGalleryIcon image={image} imageIndex={i} key={i}/>
        })}

    </section>
  )
}

export default Gallery
