import React from 'react'
import { NavLink } from 'react-router-dom'
import Hamburger from './hamburger'
import DropDown from './dropdown'

const NavBar = (props) => {
  return (
        <header className='bg-black mainFont text-white topnav'>
            <div className='w-full flex h-full'>
                {/* Home Link Image */}
                <NavLink to='/' className='m-auto ml-8 mb-5 md:ml-16 w-auto'> <img className='select-none homeImg' src={'images/NightOwlIcon.svg'} alt='Night Owl Draws Icon'/> </NavLink>
                {/* Text Links */}
                <div className='navbar relative w-full inline '>
                    <div className='absolute w-full navLinks'>
                        <nav className='w-auto float-left ml-3 '>
                            <DropDown sections={props.sections}></DropDown>
                        </nav>
                        {/* Right side links */}
                        <div className='inline-flex w-auto mr-16 float-right'>
                            <NavLink to='/commissions' exact className='hover:text-red-500 px-2 mr-3 text-xl' activeClassName="text-red-600">Commissions</NavLink>
                            <NavLink to='/about' exact className='hover:text-red-500 px-2 mr-3 text-xl' activeClassName="text-red-600">About</NavLink>
                        </div>
                    </div>
                </div>

                <Hamburger sections={props.sections}></Hamburger>

            </div>
        </header>
  )
}

export default NavBar
