import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import DropDown from './dropdown'

const Hamburger = (props) => {
  const [menuVisible, setMenu] = useState(null)

  let menuClass

  if (menuVisible !== null) { menuVisible ? menuClass = 'showMenu' : menuClass = 'hideMenu' }

  // mx-8 md:mx-16 lg:mx-16 xl:mx-16 sm:mx-8 mt-10 m-auto
  return (
        <div className={'hamburger text-white w-full h-full'}>
            <div className='w-full h-full relative'>
                <span className= 'absolute hamburger-pos mr-5 z-50 cursor-pointer text-xl'>
                    <FontAwesomeIcon icon={faBars} onClick={ () => {
                      setMenu(!menuVisible)
                    } } />
                </span>
            </div>
            <div className={`menuClass ${menuClass} `} >
                <div className='mr-5 text-right text-lg'>
                    <DropDown sections={props.sections}></DropDown>
                </div>
                <div className='mt-8 mr-5 text-right'>
                  <NavLink to='/commissions' exact className='hover:text-red-500 block text-xl' activeClassName="text-red-600">Commissions</NavLink>
                  <NavLink to='/about' exact className='hover:text-red-500 block text-xl' activeClassName="text-red-600">About</NavLink>
                </div>
            </div>

            { menuVisible
              ? <div className={'absolute h-full w-full z-20 bg-black bg-opacity-40 left-0'} onClick={ () => {
                setMenu(!menuVisible)
              }}></div>
              : null}
        </div>
  )
}

export default Hamburger
