import React from 'react'

const CommissionTile = (props) => {
  const imgUrl = props.imgUrl
  const prices = props.prices
  const artStyle = props.artStyle
  const typeArray = props.typeArray
  const fullImg = props.fullImg

  return (
        <div className="w-full relative whitebox overflow-hidden fadeIn">
            <a href={fullImg} target='_blank' rel='noopener noreferrer'> <img src={imgUrl} alt=""/> </a>
            <div className="bg-black bg-opacity-75 absolute bottom-0 pb-2 md:pb-5 pt-1 w-full">
                <p className='text-center text-sm md:text-lg xl:text-xl underline pb-1'>{artStyle}</p>
                {prices.map((price, index) => {
                  return <p key={index} className='text-white text-center text-xs md:text-lg'>{typeArray[index]} - ${price}</p>
                })}
            </div>
        </div>
  )
}

export default CommissionTile
