import React from 'react'
const Footer = () => {
  return (
    <div className='mx-8 md:mx-16 mt-4 h-1/10 flex'>
        <a href='https://www.instagram.com/NightOwl_Draws/' target='_blank' rel='noopener noreferrer'> <img className='h-auto w-auto flex select-none mr-4' style={{ height: 25, width: 25 } } src='images/instagram-circle.png' alt='instagram'></img> </a>
        <a href='https://twitter.com/NightOwl_Draws' target='_blank' rel='noopener noreferrer'> <img className='h-auto w-auto flex select-none mr-4' style={{ height: 25, width: 25 } } src='images/twitter-circle.png' alt='twitter'></img> </a>
        <a href='https://nightowl-xv.artstation.com/' target='_blank' rel='noopener noreferrer'> <img className='h-auto w-auto flex select-none' style={{ height: 25, width: 25 } } src='images/artstation2.svg' alt='artstation'></img> </a>
    </div>
  )
}

export default Footer
