import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import FullView from './fullview'

const SinglePost = (props) => {
  const { slug } = useParams()

  const [fullImg, setFullImg] = useState(-1)

  let currPost = []

  useEffect(() => { document.getElementById('gallery').scrollTop = 0 }, [])

  function getCurrentPost (post) {
    return post.slug.current === slug
  }

  function setImg (i) {
    // If 0 is sent, we assume it's a close
    if (i === 0) {
      setFullImg(-1)
    } else {
      // Wrap around since negative modolus doesn't
      if (fullImg + i === -1) {
        setFullImg(currPost[0].imagesGallery.length - 1)
      } else setFullImg((fullImg + i) % currPost[0].imagesGallery.length)
    }
  }

  if (props.imgs) {
    currPost = props.imgs.filter(getCurrentPost)
  }

  if (currPost.length === 0) { return <h1 className="text-white text-xl "> 404 Page Not Found</h1> }

  return (
        <div className='h-full w-full mainFont text-white'>
          <div className='relative'>
              <div className='absolute text-sm md:text-lg left-3 md:left-5 cursor-pointer'> <p onClick={() => {
                props.history.goBack()
              }}>Back</p> </div>
              <h1 className="text-md md:text-xl text-center my-2 underline">{currPost[0].title}</h1>
              <h2 className="text-xs md:text-md text-center mb-1">{currPost[0].description}</h2>
              <div className="block">
                  {
                      currPost[0].imagesGallery && currPost[0].imagesGallery.map((image, index) => {
                        return (
                              <div key={index}>
                                  <img src={image.asset.url + '?h=575&fm=webp' } alt={'Image ' + { index }} className="mx-auto mt-8 cursor-pointer" onClick={ () => {
                                    setFullImg(index)
                                  }
                                  } onLoad={() => { document.getElementById('gallery') }}/>
                                  <p className='text-white sm:text-sm md:text-md text-center pt-2 pb-3'>{image.desc ? image.desc : null }</p>
                              </div>

                        )
                      })
                  }
              </div>
            </div>
            {fullImg !== -1 ? <FullView className={'showBack'} setFullImg={setImg} currImg={fullImg} tabIndex="0" numImgs={currPost[0].imagesGallery.length} imgUrl={currPost[0].imagesGallery[fullImg].asset.url}></FullView> : null}
        </div>
  )
}

export default SinglePost
