import React, { useState, useEffect } from 'react'
import CommissionTile from './commissionTile'
import client from '../client'

const Commissions = () => {
// TODO:
// Add commission status in Sanity, pull status, and display here

  const [commission, setCommission] = useState(null)

  useEffect(() => {
    client.fetch(`*[_type == "commissions"][0]{
        isOpen
      }`).then((e) => { setCommission(e.isOpen) }).catch(setCommission(true))
  }, [])

  return (
    <div className='mainFont h-full fadeIn'>
        <p className='text-2xl text-center mt-1 mb-4'>Commissions</p>
        <div className="grid h-auto grid-cols-2 gap-5 md:gap-20 lg:grid-cols-4 mx-10">
            <CommissionTile imgUrl='images/flat_shaded_cropped.png' prices={[25, 35, 40]} typeArray={['Portrait', 'Half Body', 'Full Body']} artStyle="Flat Coloured" fullImg='images/flat_shaded.png'></CommissionTile>
            <CommissionTile imgUrl='images/cell_shaded_crop.jpg' prices={[35, 45, 55]} typeArray={['Portrait', 'Half Body', 'Full Body']} artStyle="Cell Shaded" fullImg='images/cell_shaded.jpg'></CommissionTile>
            <CommissionTile imgUrl='images/half_painted_cropped.png' prices={[70, 80, 120]} typeArray={['Portrait', 'Half Body', 'Full Body']} artStyle="Half Painted" fullImg='images/half_painted.png'></CommissionTile>
            <CommissionTile imgUrl='images/full_painted_cropped.png' prices={[80, 120, 160]} typeArray={['Portrait', 'Half Body', 'Full Body']} artStyle="Full Painted" fullImg='images/full_painted.png'></CommissionTile>
        </div>
        <p className=' text-center mt-2'>Prices may vary based on complexity. For any inquiries please reach out via email or social media </p>
        <div className='w-11/12 md:w-1/2 lg:w-1/4 socialLinks flex justify-between'>
            <p className='text-red-500'><a href="mailto:nightowldraws@gmail.com">Email</a></p>
            <p className='text-red-500'><a href="https://www.instagram.com/nightowl_draws/">Instagram</a></p>
            <p className='text-red-500'><a href="https://twitter.com/NightOwl_Draws">Twitter</a></p>
        </div>
        <p className='text-center'>Commissions Status: { commission ? <span className='text-green-500'>OPEN</span> : <span className='text-red-500'>CLOSED</span>}</p>
    </div>
  )
}

export default Commissions
