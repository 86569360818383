import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom'

const HomePage = (props) => {
  const [homeImages, setHomeImages] = useState([])

  useEffect(() => {
    const homeGallery = []

    sanityClient.fetch(`*[_type == 'homegallery']{
      image{
        asset->{
          url
        }
      }
    }`).then((data) => {
      data && data.forEach(element => {
        homeGallery.push({ original: element.image.asset.url + '?h=500&w=1500&fit=crop&crop=entropy&auto=format&fm=webp', originalClass: '' })
      })
      setHomeImages(homeGallery)
    }).catch(console.error)
  }, [])

  return (
    <div>
      <div className='w-auto m-4 fadeIn whitebox overflow-hidden'>
        <ImageGallery items={homeImages} lazyLoad={true} infinite={true} showBullets={true} showNav={false} showIndex={true} showPlayButton={false} showFullscreenButton={false} autoPlay={true} slideInterval={5000}></ImageGallery>
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-3 gap-x-3 md:gap-x-20 gap-y-5 m-auto h-full w-3/4 mb-5'>
        {
          props.sections && props.sections.map((currImg, index) => {
            return (
              <article className="fadeIn relative block whitebox overflow-hidden" key={index}>
                <Link className= "" to={'/gallery/' + currImg.slug.current}>
                        <img className='image m-auto select-none' src={currImg.thumbnail.asset.url + '?blur=20&h=600&w=600&fit=crop&crop=entropy&auto=format&fm=webp'} alt={currImg.title}/>
                        <div className='titleCard bg-opacity-30 bg-black'>
                            <p className='galleryTitle text-md md:text-lg lg:text-xl md:whitespace-nowrap w-auto'>{currImg.title}</p>
                        </div>
                </Link>
              </article>
            )
          })
        }
      </div>
    </div>

  )
}

export default HomePage
