import React from 'react'
import { Link } from 'react-router-dom'

const PostGalleryIcon = (props) => {
  return (
        <article className="fadeIn relative block">
            <Link className= "" to={'/post/' + props.image.slug.current}>
                    <img className='image m-auto select-none' src={props.image.thumbnail.asset.url + '?h=400&w=400&fit=crop&crop=entropy&auto=format&fm=webp'} alt={props.image.title}/>
                    <div className='titleCard overlay z-10'>
                        <p className='title text-xs md:text-sm lg:text-base'>{props.image.title}</p>
                    </div>
            </Link>
        </article>
  )
}

export default PostGalleryIcon
